import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';
import ProcessNew from '../compontents/ProcessNew/ProcessNew';
import { software_header } from './custom-software.module.scss';

type CustomSoftwarePageProps = {
    location: any;
};

const CustomSoftwarePage = ({ location }: CustomSoftwarePageProps) => {
    return (
        <Layout location={location}>
            <div data-testid="custom-software-container">
                <Helmet>
                    <title>Custom Software Development | Expert Solutions for Your Business Needs</title>
                </Helmet>

                {/*Intro Header*/}
                <div className={software_header}>
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="mb-0">Custom Software Development</h2>
                                <p className="mainText mb-0">
                                    <strong>Sometimes there’s no off-the-shelf answer for what you need.</strong>
                                </p>
                                <p>You need custom software tailored to fit your exact requirements.</p>
                                <a className="button-primary" href="/contact-us">
                                    Contact us to get started
                                </a>
                            </div>
                            <div className="col-lg-6">
                                <img src="../../images/software/software-laptop.png" className={'img-fluid'}></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/*end Intro Header*/}

                <div className="container mt-5">
                    {/*Struggle section*/}
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <h3>Streamline and Simplify Your Process</h3>
                            <p>
                                Are you tired of juggling a load of 3rd party programs to manage your business? Many
                                have overlapping redundant features, yet many are incompatible with each other, and
                                trying to work with all of them can be a pain. Maybe now you’re realizing they don’t
                                even offer the important features you need to make things easier.
                            </p>
                        </div>
                        <div className="col-md-6 px-5">
                            <img className="img-fluid" src="../../images/software/logo-mess.png" alt="too many apps" />
                        </div>
                    </div>
                    {/* endStruggle section*/}

                    {/*solutions section*/}
                    <h4>Get Custom Developed Software</h4>
                    <p>
                        By customizing, integrating, and optimizing your software, you can build a tailored solution
                        that’s perfect for your organization.
                    </p>
                    <p>
                        Custom software will provide the exact features you need. You can also optimize your workflow
                        and automate repetitive manual tasks for{' '}
                        <strong>improved productivity and increased cost-effectiveness</strong>. You can relax knowing
                        your programs are all integrated seamlessly, and your processes are as fast and effective as
                        they can be.
                    </p>

                    <div className="row mt-5">
                        <div className="col-md-6">
                            <h4>You May Be Wondering:</h4>
                            <p>We know you may have some preconceived notions about custom developed software.</p>
                            <ul>
                                <li>When will it be done?</li>
                                <li>Doesn’t it take a long time?</li>
                                <li>Isn’t it expensive?</li>
                                <li>Will I have a clear view of how development progresses?</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h4>We Have The Solution:</h4>
                            <p>
                                SquareHook will collaborate with you every step of the way. We use a DevOps process with
                                continuous integration and deployment that speeds up development many times faster than
                                traditional processes. Our methods keep costs down, implementation times short, and the
                                development process flexible and easily monitored.
                            </p>
                        </div>
                    </div>
                </div>
                {/*end container*/}
                {/*end solutions section*/}

                {/*idea section*/}
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="mb-0">Got a Great Idea For a New Software Program?</h2>
                            <h4>Bring your ideas to life with SquareHook custom software development</h4>
                            <p>
                                So, you have an idea that will revolutionize your industry or just make things easier,
                                but don&apos;t have the technical skills or time it takes to make it happen? Whether you
                                need software programming or website development and hosting, we will work with you
                                every step of the way, from ideation to deployment, to turn your vision into a reality.
                            </p>
                        </div>
                        <div className="col-md-4 center">
                            <img src="../../images/software/bulb-gear.jpg" className={'img-fluid'}></img>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-8 mx-auto">
                            {' '}
                            <p className="center mainText emphasis">
                                Don&apos;t let your great idea go to waste. Request a consultation to see how together,
                                we can turn it into an innovative success!
                            </p>
                        </div>
                    </div>
                </div>
                {/*idea section*/}

                {/*our process*/}
                {/*using a cardgroup is not responsive on small screens right now. check break points*/}
                <div id="process" className="container mt-5 pt-5">
                    <h3 className="center">How It Works</h3>
                    <ProcessNew />
                </div>
                {/*end our process*/}

                {/*CTA section*/}
                <div className="bgsection p-5 center">
                    <div className="container mainText mb-5">
                        <h2>Start By Contacting Us</h2>
                        <p>
                            So set up a free consultation today, so you can get a custom solution tailored to your
                            needs.{' '}
                        </p>
                        <a className="button-primary" href="/contact-us">
                            Contact Us
                        </a>
                        <p></p>
                    </div>
                </div>
                {/*end CTA section*/}
            </div>
        </Layout>
    );
};

export default CustomSoftwarePage;
